var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-radio-group', {
    attrs: {
      "hide-details": "auto",
      "mandatory": "",
      "row": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.marketingMethod,
      callback: function ($$v) {
        _vm.marketingMethod = $$v;
      },
      expression: "marketingMethod"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-radio', {
      key: index,
      attrs: {
        "label": item.text,
        "value": item.value
      }
    })];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }