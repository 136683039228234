<template>
    <v-radio-group v-model="gender" hide-details="auto" row mandatory @change="emit">
        <template v-for="(item, index) in items">
            <v-radio :key="index" :label="item.text" :value="item.value" />
        </template>
    </v-radio-group>
</template>

<script>
import { USER_GENDER_TYPES } from '@/assets/variables';
export default {
    props: {
        value: { type: String, default: USER_GENDER_TYPES.MALE.value }
    },
    data: () => ({
        items: Object.values(USER_GENDER_TYPES),
        gender: USER_GENDER_TYPES.MALE.value
    }),
    methods: {
        sync() {
            this.gender = this.value;
        },
        emit() {
            this.$emit('input', this.gender);
        }
    },
    watch: {
        value(){
            this.sync();
        }
    }
};
</script>