var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "name": "emailUser",
      "hide-details": ""
    },
    model: {
      value: _vm.emailUser,
      callback: function ($$v) {
        _vm.emailUser = $$v;
      },
      expression: "emailUser"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    label: _vm.label
  }), false))], 1), _c('v-col', [_c('v-combobox', _vm._b({
    attrs: {
      "name": "emailHost",
      "prefix": "@",
      "hide-details": ""
    },
    model: {
      value: _vm.emailHost,
      callback: function ($$v) {
        _vm.emailHost = $$v;
      },
      expression: "emailHost"
    }
  }, 'v-combobox', Object.assign({}, _vm.$attrs, {
    items: _vm.items
  }), false))], 1), _vm.rules && !_vm.$attrs.hideDetails ? _c('v-col', {
    staticStyle: {
      "padding": "4px 0 0 4px!important",
      "display": "none"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "email-details",
    attrs: {
      "hide-details": "auto"
    }
  }, 'v-text-field', {
    value: _vm.value,
    rules: _vm.rules
  }, false))], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }