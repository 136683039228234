<template>
    <v-radio-group v-model="marketingMethod" hide-details="auto" mandatory row @change="emit">
        <template v-for="(item, index) in items">
            <v-radio :key="index" :label="item.text" :value="item.value" />
        </template>
    </v-radio-group>
</template>

<script>
import { USER_MARKETING_METHODS } from '@/assets/variables';
export default {
    props: {
        value: { type: String, default: USER_MARKETING_METHODS.EMAIL.value }
    },
    data: () => ({
        items: Object.values(USER_MARKETING_METHODS),
        marketingMethod: USER_MARKETING_METHODS.EMAIL.value
    }),
    methods: {
        sync() {
            this.marketingMethod = this.value;
        },
        emit() {
            this.$emit('input', this.marketingMethod);
        }
    },
    watch: {
        value(){
            this.sync();
        }
    }
};
</script>