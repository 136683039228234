<template>
    <v-row align="center" class="row--xs">
        <v-col cols="12">
            <v-row align="center" class="row--xxs">
                <v-col>
                    <div class="w-md-270px">
                        <v-text-field v-model="postcode" :placeholder="label ? `${label} 우편번호` : '우편번호'" hide-details readonly :class="$attrs.className" @click="search" />
                    </div>
                </v-col>
                <v-col cols="auto">
                    <v-btn large color="grey" class="search-button" :class="btnAttrs?.className" @click="search">
                        <span class="white--text">검색</span>
                    </v-btn>
                </v-col>
                <v-col v-if="basicDestination" cols="auto" class="pa-4px d-none d-md-block">
                    <div class="pl-12px">
                        <v-checkbox v-model="isDefault" v-bind="{ ...checkbox_primary }">
                            <template v-slot:label>
                                <span class="txt txt--sm txt--dark">기본배송지로 설정</span>
                            </template>
                        </v-checkbox>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <v-text-field v-model="address1" :placeholder="label ? `${label} 기본주소` : '기본주소'" hide-details readonly :class="$attrs.className" @click="search" />
        </v-col>
        <v-col cols="12">
            <v-text-field v-model="address2" :placeholder="label ? `${label} 상세주소` : '상세주소'" hide-details="auto" :class="$attrs.className" />
            <daum-postcode ref="daum-postcode" @change="processAddressData" />
        </v-col>
        <v-col v-if="basicDestination" cols="12" class="pa-4px d-md-none">
            <v-checkbox v-model="isDefault" v-bind="{ ...checkbox_primary }">
                <template v-slot:label>
                    <span class="txt txt--sm txt--dark">기본배송지로 설정</span>
                </template>
            </v-checkbox>
        </v-col>
    </v-row>
</template>

<script>
import { btn_secondary2, checkbox_primary } from "@/assets/variables";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";

export default {
    components: {
        DaumPostcode,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // user
        label: { type: String, default: "" },
        dense: { type: Boolean, default: true },
        btnAttrs: { type: Object, default: () => ({}) }, // user
        basicDestination: { type: Boolean, default: false },
        noRules: { type: Boolean, default: false },
    },
    data: () => ({
        btn_secondary2,
        checkbox_primary,

        postcode: null,
        address1: null,
        address2: null,
        isDefault: false,
    }),
    computed: {
        rules() {
            return this.noRules ? [] : [() => (!!this.postcode && !!this.address1 && !!this.address2) || "주소를 입력해주세요"];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        postcode() {
            this.emit();
        },
        address1() {
            this.emit();
        },
        address2() {
            this.emit();
        },
        isDefault() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.postcode = this.value?.postcode || null;
            this.address1 = this.value?.address1 || null;
            this.address2 = this.value?.address2 || null;
            this.isDefault = this.value?.isDefault || false;
        },
        emit() {
            const { postcode, address1, address2, isDefault } = this;
            this.$emit("input", { ...this.value, postcode, address1, address2, isDefault });
        },
        search() {
            this.$refs["daum-postcode"]?.open?.();
        },
        processAddressData({ postcode, address }) {
            this.postcode = postcode;
            this.address1 = address;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--fab, .v-btn--icon).v-size--large.search-button {
    height: 40px !important;
}
@media (min-width: 768px) {
    .v-btn:not(.v-btn--fab, .v-btn--icon).v-size--large.search-button {
        height: 48px !important;
        width: 100px;
    }
}
</style>
