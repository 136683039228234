<template>
    <v-row align="center" class="row--xs">
        <v-col>
            <v-text-field v-model="emailUser" name="emailUser" v-bind="{ ...$attrs, label }" hide-details />
        </v-col>
        <v-col>
            <v-combobox v-model="emailHost" name="emailHost" prefix="@" v-bind="{ ...$attrs, items }" hide-details />
        </v-col>
        <v-col cols="12" v-if="rules && !$attrs.hideDetails" style="padding:4px 0 0 4px!important; display: none">
            <v-text-field v-bind="{ value, rules }" hide-details="auto" class="email-details" />
        </v-col>
    </v-row>
</template>

<script>
const items = ["naver.com", "hanmail.net", "google.com"];
export default {
    props: {
        value: { type: String, default: null }, //email
        label: { type: String, default: null },
        rules: { type: Array, default: null },
    },
    data: () => ({
        items,

        emailUser: null,
        emailHost: null,
    }),
    computed: {
        email() {
            if (this.emailUser && this.emailHost) return `${this.emailUser}@${this.emailHost}`;
            else return null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        emailUser() {
            this.emit();
        },
        emailHost() {
            this.emit();
        },
    },
    methods: {
        sync() {
            [this.emailUser = null, this.emailHost = null] = this.value?.split?.("@") || [];
        },
        emit() {
            this.$emit("input", this.email);
        },
    },
};
</script>

<style lang="scss" scoped>
.email-details {
    margin: 0;
    padding: 0;
    ::v-deep .v-input__slot {
        display: none;
    }
}
</style>
