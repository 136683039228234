<template>
    <v-row align="center" :class="{ 'row--xs': true, hasCertification }">
        <v-col>
            <v-text-field v-model="phone1" v-bind="{ ...$attrs, label, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
        </v-col>
        <v-col cols="auto">-</v-col>
        <v-col>
            <v-text-field v-model="phone2" v-bind="{ ...$attrs, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
        </v-col>
        <v-col cols="auto">-</v-col>
        <v-col>
            <v-text-field v-model="phone3" v-bind="{ ...$attrs, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
        </v-col>
        <template v-if="hasCertification">
            <v-col cols="12" sm="auto">
                <v-btn block large color="grey" @click="certify">
                    <span class="white--text">본인인증</span>
                </v-btn>
            </v-col>
            <kcp-cert ref="kcp-cert" @input="processCertifiedData" />
        </template>
    </v-row>
</template>

<script>
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
export default {
    components: {
        KcpCert,
    },
    props: {
        value: { type: String }, // phone
        label: { type: String },
        btnAttrs: { type: Object, default: () => ({}) }, // user
        dense: { type: Boolean },
        hasCertification: { type: Boolean, default: false },
    },
    data: () => ({
        phone1: "010",
        phone2: null,
        phone3: null,
        _certification: null,
    }),
    computed: {
        readonly() {
            return this.hasCertification;
        },
        phone() {
            const { phone1, phone2, phone3 } = this;
            if (!!phone1 && !!phone2 && !!phone3) return `${phone1}-${phone2}-${phone3}`;
            else return null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.phone != this.value) this.sync();
        },
        phone() {
            this.emit();
        },
        _certification() {
            this.emit();
        },
    },
    methods: {
        sync() {
            [this.phone1, this.phone2, this.phone3] = this.value?.split?.("-") || ["010", null, null];
        },
        emit() {
            this.$emit("input", this.phone);
            this.$emit("certification", this._certification);
        },
        input() {
            this.$nextTick(() => {
                this.phone1 = this.phone1?.slice?.(0, 3) || null;
                this.phone2 = this.phone2?.slice?.(0, 4) || null;
                this.phone3 = this.phone3?.slice?.(0, 4) || null;
            });
        },
        click() {
            if (this.hasCertification) this.certify();
        },
        certify() {
            this.$refs["kcp-cert"]?.auth?.();
        },
        processCertifiedData(payload) {
            [this.phone1, this.phone2, this.phone3] = payload?.phone?.phoneNumberFormat()?.split?.("-");
            this._certification = payload._certification;

            this.$emit("certify", payload);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    input {
        text-align: center;
    }
}
.hasCertification ::v-deep input {
    cursor: pointer !important;
}
</style>
