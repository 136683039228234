import { render, staticRenderFns } from "./v-address-field.vue?vue&type=template&id=c37f670c&scoped=true"
import script from "./v-address-field.vue?vue&type=script&lang=js"
export * from "./v-address-field.vue?vue&type=script&lang=js"
import style0 from "./v-address-field.vue?vue&type=style&index=0&id=c37f670c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c37f670c",
  null
  
)

export default component.exports